import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2880ad2e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-0 m-0" }
const _hoisted_2 = { class: "row m-0 mt-1 p-0 newList" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["src"]
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "d-inline-block new-list-title" }
const _hoisted_7 = { class: "d-inline-block new-list-desc" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lb = _resolveComponent("lb")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.data.lb?.length > 0)
      ? (_openBlock(), _createBlock(_component_lb, {
          key: 0,
          lb: _ctx.data.lb
        }, null, 8, ["lb"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.news, (item, index) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
          _createElementVNode("div", {
            class: "col-6 p-0 m-0 item new-list-img d-flex flex-column justify-content-center",
            onClick: ($event: any) => (_ctx.linkTo(item.id))
          }, [
            _createElementVNode("img", {
              src: _ctx.url + item.cover_url,
              class: "w-100 align-self-center h-100"
            }, null, 8, _hoisted_4)
          ], 8, _hoisted_3),
          _createElementVNode("div", {
            class: "col-6 p-0 m-0 item d-flex flex-column new-list-rigth",
            onClick: ($event: any) => (_ctx.linkTo(item.id))
          }, [
            _createElementVNode("span", _hoisted_6, _toDisplayString(item.title), 1),
            _createElementVNode("span", _hoisted_7, _toDisplayString(item.desc), 1)
          ], 8, _hoisted_5)
        ], 64))
      }), 128))
    ])
  ]))
}